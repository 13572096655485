import axios from 'axios';

const PROTOCOL = "https://"
const URL = "rambl.in"
const recipient = "GIOS"

/**
 * Send Mail
 **/
export const sendMail = (data) => {  
    const req = axios.post(PROTOCOL + URL + ':8333/contact-api/sendEmail/'+recipient,data, {
        headers: {}
    }).then(res => {
        return res;
    }).catch(error => {
        throw error;
    })
    return req
}