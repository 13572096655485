import React, { useState, useCallback, Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/utilities.css';
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";

const photos = [
	{
		src: window.location.origin + "/img/gallery/1.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/2.jpg",
		width: 1,
		height: 1
	},
	{
		src: window.location.origin + "/img/gallery/3.jpg",
		width: 3,
		height: 4
	},
	{
		src: window.location.origin + "/img/gallery/4.jpg",
		width: 3,
		height: 4
	},
	{
		src: window.location.origin + "/img/gallery/5.jpg",
		width: 3,
		height: 4
	},
	{
		src: window.location.origin + "/img/gallery/6.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/7.jpg",
		width: 3,
		height: 4
	},
	{
		src: window.location.origin + "/img/gallery/8.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/9.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/10.jpg",
		width: 3,
		height: 4
	},
	{
		src: window.location.origin + "/img/gallery/11.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/12.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/13.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/14.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/15.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/16.jpg",
		width: 4,
		height: 3
	},
	{
		src: window.location.origin + "/img/gallery/17.jpg",
		width: 4,
		height: 3
	}
];

function LightBoxGallery ()  {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		console.log('here');
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	return (
		<div>
			<Gallery photos={photos} onClick={openLightbox}></Gallery>
			<ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
			</ModalGateway>
		</div>
	);
}

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}
	
class GalleryPage extends Component {
    render() {
        return (
			<div>				
				<div class="mx-auto w-100 w-lg-75 px-4 px-lg-0">		
					<LightBoxGallery/>	
					{getSpacer()}				
				</div>			
			</div>
        );
    }
}

export default GalleryPage;
