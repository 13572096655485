import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/utilities.css';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const sliderCSS = {
	// "--slider-height-percentage": "35%",
	"--loader-bar-color" : "#000000",
	"--loader-bar-height": "6px",
	"--filter":"blur(O)"
}

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class HomePage extends Component {
    render() {
        return (
			<div>
				<div class="mx-auto w-100 w-lg-75">
					
					<div class="gios-slider-border w-100">
						<div class="m-3 no-blur">
							<AutoplaySlider className="gios-slider" style={sliderCSS} play={true} cancelOnInteraction={false} interval={8000} organicArrows={false} bullets={false}>
								<div data-src="/img/gallery/10.jpg" />
								<div data-src="/img/gallery/13.jpg" />
								<div data-src="/img/gallery/11.jpg" />
							</AutoplaySlider>
						</div>
					</div>

					{getSpacer()}

					<div class="px-4 px-lg-0">
						<div>Giovanni’s is a family owned and operated Pizzeria and Italian Restaurant that has been serving the Quakertown area since 1980. We take pride in offering quality food in a relaxed, friendly atmosphere where you can enjoy our excellent pizza, subs, Italian Entrees and more! Thank you for dining with us.</div>
						<div class="h2 pl-4 pt-2 font-weight-bold">-the Randazzo Family</div>
					</div>

					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}

				</div>
			</div>
        );
    }
}

export default HomePage;
