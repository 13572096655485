import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import Header from './components/header.js';
import Footer from './components/footer.js';
import HomePage from './pages/home.js';
import MenuPage from './pages/menu.js';
import GalleryPage from './pages/gallery.js';
import AboutPage from './pages/about.js';
import ContactPage from './pages/contact.js';
import { push as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

ReactDOM.render(
    <BrowserRouter>
        <div id="outer-container">
            <Menu pageWrapId={"page-wrap"} 
                outerContainerId={"outer-container"}  
                burgerButtonClassName={"d-lg-none"} 
                width={"280px"}
                customBurgerIcon={<FontAwesomeIcon className="txt-white cursor-pointer outline-none" icon={faBars} size="lg"/>} 
                customCrossIcon={<FontAwesomeIcon className="txt-white cursor-pointer outline-none" icon={faTimes} size="lg"/>}
                disableAutoFocus right>
                    <a id="home" className="txt-white nav-link text-center font-weight-bold outline-none" href="/">Home</a>
                    <a id="menu" className="txt-white nav-link text-center font-weight-bold outline-none" href="/menu">Menu</a>
                    <a id="gallery" className="txt-white nav-link text-center font-weight-bold outline-none" href="/gallery">Gallery</a>
                    <a id="about" className="txt-white nav-link text-center font-weight-bold outline-none" href="/about">About</a>
                    <a id="contact" className="txt-white nav-link text-center font-weight-bold outline-none" href="/contact">Contact</a>
            </Menu>
            <main id="page-wrap">
                <Header/>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route path="/menu" component={MenuPage} />
                    <Route path="/gallery" component={GalleryPage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Redirect to="/" />
                </Switch>
                <Footer/>
            </main>
        </div>
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
