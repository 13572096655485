import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import GoogleMapReact from "google-map-react";
import { sendMail } from "../api-calls/email-api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const contactLogo ="https://giovannis-pizzeria.com/img/logo/gios-white-logo.png";

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class ContactPage extends Component {
	constructor(props) {
		super(props);

		this.state={
			hideForm: false,
			isLoading: false
		}
	}

	static defaultProps = {
		center: {
			lat: 40.4464696,
			lng: -75.362059
		},
		zoom: 15
	};

	openMapExternal = () => {
		if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1) || (navigator.platform.indexOf("iPad") !== -1)) {
			/* APPLE MAPS */
			window.open("maps://maps.apple.com/?daddr=giovannis+quakertown");
		} else {
			/* GOOGLE MAPS */
			window.open("https://www.google.com/maps/dir/your+location/giovannis+quakertown,224+N+West+End+Blvd,+Quakertown,+PA+18951/");
		}
	}

	handleSubmit=(event)=> {
	  	event.preventDefault();
		const data = new FormData(event.target);
		
		var object = {};
		data.forEach((value, key) => {
			// Reflect.has in favor of: object.hasOwnProperty(key)
			if (!Reflect.has(object, key)) {
				object[key] = value;
				return;
			}
			if (!Array.isArray(object[key])) {
				object[key] = [object[key]];
			}
			object[key].push(value);
		});
		object["logo"]=contactLogo;
		
		this.setState({ isLoading: true });
		
		sendMail(object).then(res => {
			this.setState({
				hideForm: true,
				isLoading: false
			});

		}).catch(error => {
			this.setState({
				isLoading: false
			});
		})
	
	  	
	}

	showForm = () => {
		if (this.state.isLoading === true) {
			return (
				<div>
					Loading...
				</div>
			)
		}
		else if (this.state.hideForm === false) {
			return (
				<form onSubmit={this.handleSubmit} id="bac-contact-form">
					<div>
						<div>
							<div className="bac-form-error-msg" id="successfail">
								{/* {{errorMsg}} */}
							</div>
						</div>
					</div>
					<div>
						<div id="message">
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"><FontAwesomeIcon icon={faUser}/></span>
									</div>
									<input className="form-control" type="text" id="from-name" name="name" required placeholder="Full Name" />
								</div>
							</div>
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></span>
									</div>
									<input className="form-control" type="text" id="from-email" name="email" required placeholder="Email Address" />
								</div>
							</div>
							<div className="form-group">
								<textarea className="form-control" id="from-message" name="message" placeholder="Your Message" rows="10"></textarea>
							</div>
							<div className="form-group">
								<button className="btn btn-primary btn-block" type="submit">Submit <FontAwesomeIcon icon={faChevronCircleRight} size="sm"/></button>
							</div>
						</div>
					</div>
				</form>);
		} else {
			return (
				<div className="text-center">
					<br/>
					<h4>Thank You For Contacting Giovanni's Pizzeria!</h4>
					<br/>
					<br/>
					<h5>We will get back to you as soon as possible.</h5>
					<br/>
					<br/>
					<br/>
				</div>
			);
		}
	}

	render() {
		return (
			<div>
				<div class="mx-auto w-100 w-lg-75 px-4 px-lg-0">

					<div>
						<div>
							<div class="h1 p-0 m-0">Find Us</div>
							<div class="google-map-container txt-black">								
								<GoogleMapReact
                                    className="txt-black"
									bootstrapURLKeys={{key: "AIzaSyBYjCrtjEV4v5UK2e3Pg8H-DusreWct2MQ"}}
									defaultCenter={this.props.center}
									defaultZoom={16}>
										<AnyReactComponent
                                            className="txt-black"
											lat={40.4464696}
											lng={-75.362059}
											text="Giovanni's Pizzeria"
										/>
								</GoogleMapReact>
							</div>
						</div>									
						<div>
							<div class="d-flex flex-row pt-3">
								<div><FontAwesomeIcon icon={faMapMarkerAlt} size="xs"/>&nbsp;&nbsp;224 North West End Blvd.</div>
								<div class="pl-2">
									<button className="btn btn-sm btn-primary cursor-pointer" onClick={()=>this.openMapExternal()}>Get Directions</button>
								</div>
							</div>
							<div class="d-flex flex-row pt-2">
								<div><FontAwesomeIcon icon={faPhone} size="xs"/>&nbsp;(215) 538-2120</div>
								<div class="pl-2">
									<a href="tel:215-538-2120" aria-label="Phone Number">
										<button className="btn btn-sm btn-primary cursor-pointer">Call Us</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					
					{getSpacer()}

					<div>
						<div class="d-flex flex-column flex-lg-row">
							<div>
								{/* <img src="https://via.placeholder.com/900x300.png"/> */}
							</div>
							<div>
								{/* <div class="pl-0 pl-lg-5"> */}
								<div>
									<div class="h1 p-0 m-0">Hours</div>
									<table>
										<tr>
											<th class="pr-3" scope="row">Monday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>7:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Tuesday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>8:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Wednesday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>8:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Thursday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>8:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Friday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>9:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Saturday</th>
											<td>10:30AM</td>
											<td>-</td>
											<td>9:00PM</td>
										</tr>
										<tr>
											<th class="pr-3" scope="row">Sunday</th>
											<td>11:00AM</td>
											<td>-</td>
											<td>4:00PM (Seasonal)</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</div>
					
					{getSpacer()}

					<div>
						<div class="h1 p-0 m-0">Contact Us</div>			
						<div>			
							{this.showForm()}
						</div>	
					</div>

					{getSpacer()}

				</div>
			</div>
		);
	}
}

export default ContactPage;
