import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/utilities.css';

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class AboutPage extends Component {
    render() {
        return (
			<div>
				<div class="mx-auto w-100 w-lg-75 px-4 px-lg-0">

					<div class="d-flex flex-column flex-lg-row">
						<div>
							<div class="gios-border">
								<img class="p-2 w-100" src="/img/gallery/5.jpg" alt="Giovanni's Family Photo"/>
							</div>
						</div>
						<div>
							<div class="pt-4 pt-lg-0 pl-0 pl-lg-4">
								<div class="h1 p-0 m-0">About Us</div>
								<div class="pt-1">Giovanni's Pizzeria is a family owned restaurant located in Quakertown, PA. Giovanni's Pizzeria prides itself on being a family owned and operated business, run by the Randazzo family over several generations. Giovanni's is nestled right in the hub of the Trainer's Corner Shopping Center, making it a great stop for a bite to eat. Giovanni's Pizzeria is a local favorite, has great prices, and the food will always leave you wanting more.</div>
							</div>
						</div>
					</div>

					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					{getSpacer()}
					
				</div>
			</div>
        );
    }
}

export default AboutPage;
