import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from "@fortawesome/free-solid-svg-icons";

const desktopHeaderCSS = {
	position: 'relative',
	height: '150px',
	zIndex: '1'
}

const mobileHeaderCSS = {
	position: 'relative',
	height: '80px',
	zIndex: '1'
}

const mobileLogoCSS = {
	height: '50px',
	width: '50px',
}

const navButtonCSS = {

}
	
class Header extends Component {
	constructor() {
		super()
		this.state={
			visible:false
		}
	}
	toggleState() {
		this.setState({
			visible:!this.state.visible
		})
	}
    render() {
        return (
			<div>
				{/* Desktop */}
				<div class="d-none d-lg-block" style={desktopHeaderCSS}>
					<div>	
						<div class="d-flex flex-row justify-content-center">
							<div>
								<Link to="/home" className="nav-link">
									<div class="h1 p-0 m-0 txt-chalk txt-white">GIOVANNI'S PIZZERIA</div>
								</Link>
							</div>
						</div>
					</div>
					<div>
						<div class="d-flex flex-row justify-content-center">
							<div class="px-4">
								<Link to="/home" className="nav-link p-0 m-0">
									<div class="gios-border px-4">
										<div class="txt-white" style={navButtonCSS}>Home</div>
									</div>
								</Link>
							</div>
							<div class="px-4">
								<Link to="/menu" className="nav-link p-0 m-0">
									<div class="gios-border px-4">									
										<div class="txt-white" style={navButtonCSS}>Menu</div>									
									</div>
								</Link>
							</div>
							<div class="px-4">
								<Link to="/gallery" className="nav-link p-0 m-0">
									<div class="gios-border px-4">									
										<div class="txt-white" style={navButtonCSS}>Gallery</div>
									</div>
								</Link>
							</div>
							<div class="px-4">
								<Link to="/about" className="nav-link p-0 m-0">
									<div class="gios-border px-4">
										<div class="txt-white" style={navButtonCSS}>About</div>
									</div>
								</Link>
							</div>
							<div class="px-4">
								<Link to="/contact" className="nav-link p-0 m-0">
									<div class="gios-border px-4">
										<div class="txt-white" style={navButtonCSS}>Contact</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				{/* Mobile */}
				<div class="d-lg-none" style={mobileHeaderCSS}>
					<div class="d-flex flex-row justify-content-between align-items-center">
						<div>
							<Link to="/home" className="nav-link">
								<img src="/img/logo/gios-white-logo.png" alt="Giovannis Pizzeria Logo" style={mobileLogoCSS} />
							</Link>
						</div>
						<div>
							{/* <div class="pr-3">
								<FontAwesomeIcon className="txt-white cursor-pointer" icon={faBars} size="lg" onClick={()=>this.toggleState()}/>
							</div> */}
						</div>
					</div>
				</div>
				<div>
					{this.state.visible?					
						<div style={{zIndex:1}}>
							<div>
								<Link to="/home" className="nav-link">
									<div class="txt-white">Home</div>
								</Link>
							</div>
							<div>
								<Link to="/menu" className="nav-link">
									<div class="txt-white">Menu</div>
								</Link>
							</div>
							<div>
								<Link to="/gallery" className="nav-link">
									<div class="txt-white">Gallery</div>
								</Link>
							</div>
							<div>
								<Link to="/about" className="nav-link">
									<div class="txt-white">About</div>
								</Link>
							</div>
							<div>
								<Link to="/contact" className="nav-link">
									<div class="txt-white">Contact</div>
								</Link>
							</div>
						</div>
					:null}
				</div>
			</div>
        );
    }
}

export default Header;
